import { useMemo } from 'react';
import * as dateUtil from '@goodfynd/react-web.utils.date-util';
import * as stringUtil from '@goodfynd/react-web.utils.string-util';

import endpoints from '../../services/api/endpoints';
import fetch from '../../services/api/authenticated-fetch-wrapper';

import type { SearchRequest } from '../../types/search';
import type {
  MessageCustomerRequest,
  OrderCancelRequest,
  OrderSearchRequest,
  OrdersRequest,
  ReceiptRequest,
} from '../../types/orders';
import type {
  OrderResponse,
  OrderSearchResponse,
  TransactionsResponse,
} from '../../services/api/types';

export type ModifyOrderActions = 'complete' | 'prepare' | 'ready';
export const useOrderApi = () => {
  return useMemo(() => {
    const api = fetch();
    return {
      async cancel(
        id: string,
        request: OrderCancelRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.orders.cancel, {
              ':id': id,
            }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getDayOrders({
        date,
        ...request
      }: OrdersRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.search,
            {
              ...request,
              dateEnd: date && dateUtil.endOfDay(date).toISOString(),
              dateStart: date && dateUtil.startOfDay(date).toISOString(),
              rows: 480,
              tags: 'complete,in progress',
            }
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getOrder(id: string): Promise<OrderResponse> {
        try {
          const data: TransactionsResponse = await api.get(
            endpoints.orders.transactions,
            {
              id,
            }
          );

          if (data.orders.length) {
            return {
              success: true,
              ...data,
              order: data.orders[0],
            };
          }

          return Promise.reject({
            success: false,
            error: {
              message: 'An unknown error occurred',
            },
          });
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getTransactions(ids: string): Promise<TransactionsResponse> {
        try {
          const data: TransactionsResponse = await api.get(
            endpoints.orders.transactions,
            {
              ids,
            }
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async modify(
        id: string,
        action: ModifyOrderActions = 'complete',
        transactionId: string | undefined = undefined
      ): Promise<OrderResponse> {
        try {
          let endpoint = '';
          switch (action) {
            case 'prepare':
              endpoint = endpoints.orders.prepare;
              break;

            case 'ready':
              endpoint = endpoints.orders.ready;
              break;

            default:
              endpoint = endpoints.orders.complete;
          }

          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoint, {
              ':id': id,
              ':transactionId': transactionId || '',
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async search(query: OrderSearchRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.search,
            {
              page: 1,
              rows: 48,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },

      async sendReceipt(
        id: string,
        params: ReceiptRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.get(
            stringUtil.replace(endpoints.orders.send.receipt, {
              ':id': id,
            }),
            params
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async sendSms(
        id: string,
        request: MessageCustomerRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.get(
            stringUtil.replace(endpoints.orders.send.sms, {
              ':id': id,
            }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, []);
};
