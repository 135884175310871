import { CancelEventRequest } from './../../types/events.d';
import { useMemo } from 'react';

import * as stringUtil from '@goodfynd/react-web.utils.string-util';
import endpoints from '../../services/api/endpoints';
import fetch from '../../services/api/authenticated-fetch-wrapper';

import { SearchRequest } from '../../types/search';
import type { ScheduleSearchRequest } from '../../types/schedule';
import type {
  EventResponse,
  ScheduleSearchResponse,
} from '../../services/api/types';

export const useScheduleApi = () => {
  return useMemo(() => {
    const api = fetch();
    return {
      async cancel({
        id,
        ...request
      }: CancelEventRequest): Promise<EventResponse> {
        try {
          const response: EventResponse = await api.post(
            stringUtil.replace(endpoints.schedule.cancel, {
              ':id': id,
            }),
            request
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async lotSearch(
        query: ScheduleSearchRequest
      ): Promise<ScheduleSearchResponse> {
        try {
          const response: ScheduleSearchResponse = await api.get(
            endpoints.schedule.lotSearch,
            {
              page: 1,
              rows: 48,
              ...query,
            } as SearchRequest
          );
          return { success: true, ...response };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async search(
        query: ScheduleSearchRequest
      ): Promise<ScheduleSearchResponse> {
        try {
          const response: ScheduleSearchResponse = await api.get(
            endpoints.schedule.search,
            {
              page: 1,
              rows: 48,
              ...query,
            } as SearchRequest
          );
          return { success: true, ...response };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, []);
};
