import React from 'react';

import loadingAnimation from './animations/loading-truck.json';
import Loading from './Loading';

import type { LoadingProps } from './types';
export default function LoadingTruck(
  props: Pick<LoadingProps, 'style'>
): JSX.Element {
  return <Loading animationData={loadingAnimation} {...props} />;
}
