import env from '../../config/env';
import routes from '../../config/routes';
import { AuthResult } from './types';

export const userAuthenticated = (
  userIsLoading: boolean | undefined,
  error: Error | undefined
): AuthResult => {
  if (userIsLoading) {
    return {
      hasError: undefined,
    };
  }

  if (!error) {
    return {
      hasError: false,
    };
  }

  const result = {
    hasError: true,
  } as AuthResult;

  switch (error.message) {
    case 'unverified':
      result.redirectUrl = env.APP_HOST + routes.account.unverified;
      break;
    case 'Invalid state':
      result.redirectUrl = routes.account.signIn;
      break;
  }
  return result;
};
