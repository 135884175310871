import { VisibleLotRequest } from './../../types/lots.d';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import * as stringUtil from '@goodfynd/react-web.utils.string-util';
import endpoints from '../../services/api/endpoints';
import fetch from '../../services/api/authenticated-fetch-wrapper';

import type { Suggestion } from '../../types/shared';
import type {
  AddRemoveVendorInterestRequest,
  AddUpdateEventRequest,
  BookVendorRequest,
  CancelEventRequest,
  ConfirmRejectLotEventRequest,
} from '../../types/events';
import type { AutocompleteRequest, SearchRequestV2 } from '../../types/search';
import type {
  AutoCompleteResponse,
  EventResponse,
  EventSearchResponse,
  EventsOpenResponse,
  LikeResponse,
  LotEventsResponse,
  ManagedLotResponse,
  VendorEventsResponse,
} from '../../services/api/types';
import { LotItem } from '../../types/lots';

export const useEventApi = () => {
  return useMemo(() => {
    const api = fetch();
    return {
      async addEvent(request: AddUpdateEventRequest) {
        try {
          const data: EventResponse = await api.postFile(
            endpoints.lots.create,
            {
              ...request,
              sector: 'trucks',
            }
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getByLot(lotId: string): Promise<LotEventsResponse> {
        try {
          const data: LotEventsResponse = await api.get(
            stringUtil.replace(endpoints.events.byLot, { ':id': lotId })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async updateEvent(id: string, request: AddUpdateEventRequest) {
        try {
          const data: EventResponse = await api.putFile(
            stringUtil.replace(endpoints.lots.base, {
              ':id': id,
            }),
            { ...request, sector: 'trucks' }
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.events.autocomplete,
            { ...request, sector: 'trucks' }
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.get(
            stringUtil.replace(endpoints.lots.base, { ':id': id })
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getLotEvents(
        lotId: string,
        type: 'interested' | 'confirmed' | 'awaiting-confirmation'
      ): Promise<VendorEventsResponse> {
        let endpoint: string;
        try {
          switch (type) {
            case 'interested':
              endpoint = endpoints.lots.events.interested;
              break;
            case 'confirmed':
              endpoint = endpoints.lots.events.confirmed;
              break;
            case 'awaiting-confirmation':
              endpoint = endpoints.lots.events.awaitingConfirmation;
              break;
          }

          const data: VendorEventsResponse = await api.get(
            stringUtil.replace(endpoint, {
              ':id': lotId,
            })
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async lotSearch(query: SearchRequestV2): Promise<EventSearchResponse> {
        try {
          const data: EventSearchResponse = await api.get(
            endpoints.events.lotSearch,
            omitBy(
              {
                page: 1,
                rows: 24,
                sector: 'trucks',
                ...query,
              },
              isNil
            )
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async notifyOpenDates(
        lotId: string,
        dates: Date[]
      ): Promise<ResponseBase> {
        try {
          const data: EventsOpenResponse = await api.post(
            stringUtil.replaceAll(endpoints.lots.notifyVendors, {
              ':id': lotId,
            }),
            dates
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async search(query: SearchRequestV2): Promise<EventSearchResponse> {
        try {
          const data: EventSearchResponse = await api.get(
            endpoints.events.search,
            omitBy(
              {
                page: 1,
                rows: 24,
                sector: 'trucks',
                ...query,
              },
              isNil
            )
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async addInterest({
        id,
        ...request
      }: AddRemoveVendorInterestRequest): Promise<EventResponse> {
        try {
          const data: EventResponse = await api.post(
            stringUtil.replace(endpoints.lots.interest.add, { ':id': id }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async removeInterest({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.interest.remove, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async bookVendor({
        lotId,
        ...request
      }: BookVendorRequest): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.bookVendor, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async cancelDate({
        id,
        description,
        reason,
      }: CancelEventRequest): Promise<EventResponse> {
        try {
          const data: EventResponse = await api.post(
            stringUtil.replaceAll(endpoints.events.cancelDate, {
              ':id': id,
            }),
            { description, reason }
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async confirm({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.vendorConfirm, { ':id': lotId }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async reject({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.vendorRejection, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async merchants(id: string): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.get(
            stringUtil.replace(endpoints.lots.base, { ':id': id })
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async visible(request: VisibleLotRequest): Promise<ManagedLotResponse> {
        try {
          const data: LotItem = await api.patch(
            stringUtil.replaceAll(endpoints.lots.visible, {
              ':id': request.id,
              ':visible': request.visible.toString(),
            })
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, []);
};
