import { useQuery } from 'react-query';

import config from '../../config';
import { useOrganizationApi } from '../../hooks/api';

import type { OrganizationStructure } from '@goodfynd/react-web.dashboard.organization-tree';

export default function useOrganizationStructure(organizationId?: string) {
  const api = useOrganizationApi();
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    OrganizationStructure,
    unknown
  >(
    [config.queryKeys.organizationStructure, organizationId],
    () =>
      organizationId ? api.getStructure(organizationId) : Promise.reject(),
    {
      enabled: !!organizationId,
    }
  );

  return {
    isFetched,
    isFetching,
    isLoading,
    refetch,
    structure: data,
  };
}
