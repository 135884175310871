import { useMemo } from 'react';

import type { AutocompleteRequest } from '../../types/search';
import type { CategoryItem, Suggestion } from '../../types/shared';
import * as stringUtil from '@goodfynd/react-web.utils.string-util';

import endpoints from '../../services/api/endpoints';
import fetch from '../../services/api/authenticated-fetch-wrapper';

import type {
  VendorResponse,
  CategoriesResponse,
  AutoCompleteResponse,
} from '../../services/api/types';

export const useVendorApi = () => {
  const api = fetch();

  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.vendors.autocomplete,
            request
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<VendorResponse> {
        try {
          const data: VendorResponse = await api.get(
            stringUtil.replace(endpoints.vendors.base, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getCategories(vendorId: string): Promise<CategoriesResponse> {
        try {
          const data: CategoryItem[] = await api.get(
            endpoints.categories.search,
            {
              includeHidden: true,
              vendorId,
            }
          );
          return { success: true, categories: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, []);
};
