import { dateFormats, emojis, unicode } from '@goodfynd/react-web.lib.strings';

import app from './app';
import env from './env';
import routes from './routes';

export { dateFormats, emojis, unicode } from '@goodfynd/react-web.lib.strings';

export enum AppMode {
  Admin = 1,
  Normal = 0,
  Testing = 2,
}

export default {
  app,
  env,
  routes,
  coordinates: '39.1157,-77.5633',
  dateFormats,
  defaultLocation: {
    coordinates: '38.805, -77.047',
    latitude: 38.805,
    longitude: -77.047,
  },
  emojis,
  queryKeys: {
    auth: 'fynd.auth',
    authUser: 'fynd.auth.user',
    cart: 'fynd.query.cart',
    cms: 'fynd.query.cms',
    dietary: 'fynd.query.dietary',
    event: 'fynd.query.vendor.event',
    eventMerchants: 'fynd.query.vendor.event.merchants',
    favoriteVendors: 'fynd.query.favorite.vendors',
    licenseTypes: 'fynd.query.licenses',
    lotEvents: 'fynd.query.lot.events',
    managedLots: 'fynd.query.lots.managed',
    menuSearch: 'fynd.query.menuSearch',
    merchant: 'fynd.query.merchant',
    openVendors: 'fynd.query.open.vendors',
    order: 'fynd.query.order',
    orders: 'fynd.query.orders',
    orderSearch: 'fynd.query.orders.search',
    orderSearchAll: 'fynd.query.orders.search.all',
    orderSearchEventSales: 'fynd.query.orders.search.event.sales',
    orderSearchInvoices: 'fynd.query.orders.search.invoices',
    orderSearchInvoicesAll: 'fynd.query.orders.search.invoices.all',
    orderSearchInvoicesOutstanding:
      'fynd.query.orders.search.invoices.outstanding',
    orderSearchInvoicesPaid: 'fynd.query.orders.search.invoices.paid',
    orderSearchInvoicesRefunded: 'fynd.query.orders.search.invoices.refunded',
    orderSearchInvoicesVoided: 'fynd.query.orders.search.invoices.voided',
    orderSearchUpcoming: 'fynd.query.orders.search.upcoming',
    orderSearchToday: 'fynd.query.orders.search.today',
    orderSearchTomorrow: 'fynd.query.orders.search.tomorrow',
    orderSearchFuture: 'fynd.query.orders.search.future',
    orderSearchWalkups: 'fynd.query.orders.search.walkups',
    ordersOverview: 'fynd.query.orders.overview',
    ordersOverviewAdmin: 'fynd.query.orders.overview.admin',
    ordersReport: 'fynd.query.orders.report',
    organization: 'fynd.query.organization',
    organizationStructure: 'fynd.query.organization.structure',
    organizationTerritories: 'fynd.query.organization.territories',
    payouts: 'fynd.query.payouts',
    payoutTransactions: 'fynd.query.payouts.transactions',
    searchEvents: 'search-events',
    searchProducts: 'search-products',
    searchVendors: 'search-vendors',
    scheduleSearch: 'fynd.query.schedule.search',
    scheduleSearchOverview: 'fynd.query.schedule.search.overview',
    scheduleSearchPast: 'fynd.query.schedule.search.past',
    scheduleSearchUpcoming: 'fynd.query.schedule.search.upcoming',
    scheduleWalkup: 'fynd.query.schedule.walkup',
    transactions: 'fynd.query.transactions',
    vendor: 'fynd.query.vendor',
    vendorEvents: 'fynd.query.vendor.events',
    vendorProducts: 'fynd.query.vendor.products',
    vendorCategories: 'fynd.query.vendor.categories',
    vendorSettings: 'fynd.query.vendor.settings',
    product: 'fynd.query.product',
    categories: 'fynd.query.categories',
    vendorProductOptions: 'fynd.query.vendor.product.options',
    category: 'fynd.query.category',
    productGroup: 'fynd.query.product-group',
  },
  storageKeys: {
    appleAuth: 'fynd.apple.auth',
    colorScheme: 'fynd.color.scheme',
    guestOrder: 'fynd.guest.order',
    rootState: 'fynd.root',
    userLocation: 'fynd.user.location',
  },
  unicode,
};

export function showOopsAlert(): void {
  alert(`An unknown error has occurred ${emojis.sad}.`);
}

export function showTimedOutAlert(): void {
  alert(`Application timed out ${emojis.sad}.`);
}
