import random from 'lodash/random';

import { numberUtil } from '@goodfynd/react-web.utils.number-util';

import config from '../config';
import images from '../config/images';
import * as dateUtil from './date-util';

import type { EventTableItem } from '@goodfynd/react-web.dashboard.tables.events-table';
import type { EventItem as EventItem2 } from '../types/events';
import type { ScheduleItem } from '../types/schedule';
import { LotItem } from '../types/lots';

export const getAddressLabel = (
  eventItem: ScheduleItem,
  fallback = 'Get Directions'
): string => {
  if (eventItem.location?.address) {
    return eventItem.location.address;
  }

  if (eventItem.location?.city && eventItem.location?.state) {
    return `${eventItem.location.city}, ${eventItem.location.state}`;
  }

  return fallback;
};

export const getEventDateFormat = (eventItem: ScheduleItem): string => {
  const parts = [];
  const date = eventItem.date || eventItem.start;
  if (date) {
    parts.push(dateUtil.format(date, config.dateFormats.weekDayMonthDayYear));
  }

  parts.push(dateUtil.getTimeRangeDisplay(eventItem.start, eventItem.end));
  return parts.join(' ');
};

export const getEventTimeLabel = ({ end, start }: IntervalDates): string => {
  let label = dateUtil.format(start as Date, config.dateFormats.timeSpace);
  label += config.unicode.dashEnPadded;
  label += dateUtil.format(end as Date, config.dateFormats.timeSpace);
  return label;
};

export const getImageFallback = (): string => {
  const imageKeys = Object.keys(images.fallbacks.event);
  const imageKey = imageKeys[
    numberUtil.random(0, imageKeys.length - 1)
  ] as keyof typeof images.fallbacks.event;
  return images.fallbacks.event[imageKey];
};

export const getAverageCost = (quantity: number, total: number): number => {
  return Math.ceil(total / quantity);
};

export const getLotImage = (image?: string) => {
  let imageUrl = image || '';
  if (!imageUrl) {
    const imageKeys = Object.keys(images.fallbacks.event);
    const imageKey = imageKeys[
      random(0, imageKeys.length - 1)
    ] as keyof typeof images.fallbacks.event;
    imageUrl = images.fallbacks.event[imageKey];
  }

  return imageUrl;
};

export const getLotName = (
  eventItem: LotItem | EventTableItem | EventItem2
) => {
  return [eventItem.organization, eventItem.name].filter(Boolean).join(': ');
};

export const getQrCodeLink = (id: string) =>
  `https://qr.goodfynd.com/media/event/${id}`;

export function openImageInNewTab(data: string) {
  const newWindow = window.open();
  newWindow?.document.write('<img src="' + data + '" />');
}
