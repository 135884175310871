import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { getGeocode } from 'use-places-autocomplete';

import { useSnackbar } from '@goodfynd/react-web.context.snackbar-context';
import { useConfirmationDialog } from '@goodfynd/react-web.ui.dialog';

import config from '../../config';
import { useUserApi } from '../../hooks/api';
import { useEventApi } from '../../hooks/api';
import { useStores } from '../../stores';
import { baseUtil, geoUtil } from '../../utils';
import { useApp, useAppDispatch } from '../AppContext';
import { useLocation, useLocationDispatch } from '../LocationContext';
import useLotEvents from './useLotEvents';

import type { ManagedLotResponse } from '../../services/api/types';
import type { BookVendorRequest, CancelEventRequest } from '../../types/events';
import type {
  EventContextValue,
  EventDispatchContextValue,
  EventProviderProps,
} from './types';
import { LotItem, VisibleLotRequest } from '../../types/lots';

export const EventContext = createContext<EventContextValue | undefined>(
  undefined
);
export const EventDispatchContext = createContext<
  EventDispatchContextValue | undefined
>(undefined);

export default observer(function EventProvider({
  children,
}: EventProviderProps) {
  const api = useEventApi();
  const userApi = useUserApi();
  const router = useRouter();
  const { setEvent: changeEvent, eventId } = useStores();
  const queryClient = useQueryClient();

  const { user } = useApp();
  const { refreshAuth } = useAppDispatch();

  const { isLoaded } = useLocation();
  const { setAddress, setPosition } = useLocationDispatch();

  const { getConfirmation } = useConfirmationDialog();
  const { openSnackbar } = useSnackbar();

  // Fetch event profile info
  const requestId = (router.query.eventId as string) || eventId;
  const queryKey: QueryKey = useMemo(
    () => [config.queryKeys.event, requestId],
    [requestId]
  );

  const {
    data: { entry: lotItem = {} as LotItem } = {},
    isLoading,
    isFetching,
    refetch: refetchEvent,
  } = useQuery<ManagedLotResponse, unknown, ManagedLotResponse>(
    queryKey,
    () => api.get(requestId as string),
    {
      enabled: !!requestId,
      onSuccess(data) {
        data.entry && changeEvent(data.entry.nameId);
      },
    }
  );

  const setAsDefaultProfile = useMutation(
    () => {
      return userApi.setDefaultProfile(user.id, lotItem.id);
    },
    {
      onSuccess() {
        refreshAuth();
      },
    }
  );

  const eventDates = lotItem?.events?.map((event) => event.start);

  const {
    awaiting,
    confirmed,
    dateRecords,
    events,
    isLoading: eventsIsLoading,
    registered,
  } = useLotEvents(lotItem.id);

  const invalidateEvent = useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: (query) => isEqual(query.queryKey, queryKey),
    });
  }, [queryClient, queryKey]);

  const visibleLot = useCallback(
    async (request: VisibleLotRequest) => {
      if (
        await getConfirmation({
          title: 'Event visibility',
          message: `Are you sure you want to ${
            request.visible ? 'show' : 'hide'
          } this event?`,
        })
      ) {
        try {
          await api.visible(request);
          refetchEvent();

          openSnackbar({
            message: `Event has been ${request.visible ? 'shown' : 'hidden'}`,
            type: 'success',
          });
        } catch (error) {
          console.error('Error event visibility: ', error);
          openSnackbar({
            message: 'An unknown error has occurred.',
            type: 'error',
          });
        }
      }
    },
    [api, getConfirmation, openSnackbar, refetchEvent]
  );

  useEffect(() => {
    if (lotItem && lotItem?.location?.address && isLoaded) {
      getGeocode({ address: lotItem.location.address }).then(([result]) => {
        const {
          latitude = 0,
          longitude = 0,
          address,
        } = geoUtil.mapGeoResult(result);
        setAddress({
          id: result.place_id,
          label: result.formatted_address,
        });

        setPosition({
          latitude,
          longitude,
          address,
        });
      });
    }
  }, [lotItem, isLoaded, setAddress, setPosition]);

  const bookVendor = useCallback(
    async (request: BookVendorRequest) => {
      if (
        await getConfirmation({
          title: 'Book Vendor',
          message: `Are you sure you want to request this vendor?\nThis selection cannot be reversed.`,
        })
      ) {
        try {
          await api.bookVendor(request);

          await queryClient.refetchQueries([
            config.queryKeys.lotEvents,
            lotItem.id,
          ]);

          openSnackbar({
            message: `Vendor has been notified! We will let you know if they are still available.`,
            type: 'success',
          });
        } catch (error) {
          console.error('Error booking vendor: ', error);
          openSnackbar({
            message: 'An unknown error has occured.',
            type: 'error',
          });
        }
      }
    },
    [api, lotItem.id, getConfirmation, openSnackbar, queryClient]
  );

  const cancelEventDate = useCallback(
    async (request: CancelEventRequest) => {
      if (
        await getConfirmation({
          title: 'Cancel Event',
          message: `Are you sure you want to cancel this event?`,
        })
      ) {
        try {
          await api.cancelDate(request);

          await queryClient.refetchQueries([
            config.queryKeys.lotEvents,
            lotItem.id,
          ]);

          openSnackbar({
            message: `Event has been canceled.`,
            type: 'success',
          });
        } catch (error) {
          console.error('Error canceling vendor: ', error);
          openSnackbar({
            message: 'An unknown error has occured.',
            type: 'error',
          });
        }
      }
    },
    [api, lotItem.id, getConfirmation, openSnackbar, queryClient]
  );

  return (
    <EventContext.Provider
      value={{
        awaiting,
        confirmed,
        dateRecords,
        events,
        eventsIsLoading,
        eventId: lotItem?.id,
        eventNameId: lotItem?.nameId || requestId || user.defaultLotId || '',
        eventItem: ({ ...lotItem, dates: eventDates } || {}) as LotItem,
        isLoading: isLoading || isFetching,
        registered,
      }}
    >
      <EventDispatchContext.Provider
        value={{
          bookVendor,
          cancelEventDate,
          changeEvent,
          invalidateEvent,
          isCurrentEvent: (entity) =>
            !!requestId && baseUtil.idEquals(requestId, entity),
          refetchEvent,
          visibleLot,
          setAsDefaultProfile: setAsDefaultProfile.mutate,
        }}
      >
        {children}
      </EventDispatchContext.Provider>
    </EventContext.Provider>
  );
});
