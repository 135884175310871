import Head from 'next/head';
import React from 'react';

import { SIDE_NAV_WIDTH } from '@goodfynd/react-web.layout.side-nav';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { withNoSSR } from '../../../hooks';
import { useRequireAuth } from '../../../hooks/useRequireAuth';
import SideNav from '../SideNav';
import { StyledBody } from './styles';
import { isEmpty } from 'lodash';
import { useEvent } from '../../../context/EventContext';
import NoProfileAlert from '../NoProfileAlert/NoProfileAlert';

function Layout({ children }: LayoutProps) {
  const isLoggedIn = useRequireAuth();
  const { eventItem } = useEvent();

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <NoSsr>
        {isLoggedIn && (
          <Box sx={{ display: 'flex' }}>
            <SideNav />
            <Box
              component="main"
              sx={{
                height: '100%',
                width: {
                  sm: `calc(1440px - ${SIDE_NAV_WIDTH}px + 32px)`,
                  xs: '100%',
                },
              }}
            >
              <StyledBody>
                {isEmpty(eventItem) && <NoProfileAlert />}
                {children}
              </StyledBody>
            </Box>
          </Box>
        )}
      </NoSsr>
    </>
  );
}

export default withNoSSR(Layout);
