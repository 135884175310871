import { styled } from '@goodfynd/react-web.theme';

export const StyledAlertBody = styled('div', {
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  gap: 16,
  alignItems: 'center',
});
