import type { NavLink } from '@goodfynd/react-web.layout.header/nav';
import routes from '../../../config/routes';
import { getDashboardRoutePath } from '../../../utils/routes-util';

export const SIDE_NAV = (profileId: string): NavLink[] => [
  {
    hide: true,
    href: profileId
      ? getDashboardRoutePath(routes.dashboard.overview, profileId)
      : routes.dashboard.home,
    icon: 'home',
    label: 'Overview',
  },
  {
    href: getDashboardRoutePath(routes.dashboard.events.home, profileId),
    icon: 'location_pin',
    label: 'Event dates',
  },
  {
    href: getDashboardRoutePath(routes.dashboard.flyers, profileId),
    icon: 'pdf',
    label: 'Flyers',
  },
  {
    href: getDashboardRoutePath(routes.dashboard.schedule.home, profileId),
    icon: 'calendar',
    label: 'Schedule',
  },
  {
    id: 'orders',
    href: getDashboardRoutePath(routes.dashboard.orders, profileId),
    icon: 'order',
    label: 'Orders',
  },
  {
    href: getDashboardRoutePath(routes.dashboard.reports.home, profileId),
    icon: 'reports',
    label: 'Reports',
  },
  {
    href: getDashboardRoutePath(routes.dashboard.info.home, profileId),
    icon: 'info',
    label: 'Event info',
  },
  {
    href: routes.support.home,
    icon: 'help',
    label: 'Support',
  },
];
