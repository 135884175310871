import React, { useCallback, useState } from 'react';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from '../../../utils/routes-util';
import routes from '../../../config/routes';
import env from '../../../config/env';
import { useRouter } from 'next/router';
import { Alert, Button, FilterMenuOption } from '@goodfynd/react-web.lib.ui';
import { StyledAlertBody } from './styles';
import { StyledText } from '@goodfynd/react-web.lib.typography';
import { ProfilesModal } from '@goodfynd/react-web.lib.dashboard';
import { AutocompleteEventOrganizations } from '../../events/AutocompleteEventOrganizations';

const NoProfileAlert = () => {
  const [openProfilesModal, toggleProfilesModal] = useState(false);
  const { push } = useRouter();
  const handleAddNew = useCallback(() => {
    push(routes.dashboard.add);
  }, [push]);

  const handleSelect = useCallback(
    (id, type) => {
      switch (type) {
        case 'lot':
          push(getDashboardRoutePath(routes.dashboard.events.home, id));
          break;

        case 'organization':
          push(getOrganizationRoutePath(routes.organization.overview, id));
          break;

        case 'vendor':
          window.open(`${env.VENDOR_APP_HOST}/dashboard/${id}`);
          break;

        default:
          break;
      }
    },
    [push]
  );

  return (
    <>
      <Alert
        type="warning"
        css={{
          margin: '8px 32px',
          '& > span': {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
          },
        }}
      >
        <StyledAlertBody>
          <StyledText>
            Please select a profile from the profile switcher
          </StyledText>
          <Button
            display="inherit"
            size="sm"
            onClick={() => toggleProfilesModal(true)}
          >
            Select Profile
          </Button>
        </StyledAlertBody>
      </Alert>
      <ProfilesModal
        addButtonLabel="Add event"
        autocompleteComponent={
          <AutocompleteEventOrganizations
            css={{ paddingBottom: 0, paddingTop: 24 }}
            onChange={(option: FilterMenuOption) => {
              handleSelect(option.value, option.type);
              toggleProfilesModal(false);
            }}
          />
        }
        close={() => toggleProfilesModal(false)}
        open={openProfilesModal}
        onAdd={handleAddNew}
        onSelect={handleSelect}
      />
    </>
  );
};

export default NoProfileAlert;
